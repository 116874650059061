ion-modal#DatePickerModalId {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    border: 1px solid var(--ion-color-custom-dark);
  }

ion-modal#DatePickerModalId .datePickerWrapper .datePicker {
    color: var(--ion-color-custom-dark);
}
  
