.caseFolderTableWrapper {
  --background: transparent;
}

.caseFolderTableWrapper .table{
  background: white;
}

.caseFolderTableWrapper .table .tHeader {
  font-weight: 500;
  border-bottom: 1px solid #707070;
}

.caseFolderTableWrapper .table .tRow {
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caseFolderTableWrapper .table .tRow:nth-child(odd) {
  background-color: #f5f5f5;
}

.caseFolderTableWrapper .table .tRow .cell:last-child,
.caseFolderTableWrapper .table .tHeader .title:last-child {
  display: flex;
  justify-content: center;
}
